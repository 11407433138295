<template>
  <div style="min-height:100vh;background: linear-gradient(140deg, #163876, #2A5298);">
    <navbar @asideFlagShow="asideFlagShow"></navbar>
    <div class="main">
      <div class="curren-level" :class="userLv==0?'p0':''">
        <van-image width="100%" height="100%" v-if="userLv>0" :src="require(`@/assets/cls/p${userLv}.png`)" />
        <van-image width="86px" height="86px" v-else :src="require(`@/assets/cls/p0.png`)" />
        <div class="p">
          <div class="level">P{{ userLv }}</div>
        </div>
        <div class="word">{{$t('nft.grade')}}</div>
      </div>
      <div class="title">NFT</div>
      <div class="list">
        <div
          class="item"
          @click="i = index"
          :class="i==index?'current':''"
          v-for="(item, index) in list"
          :key="item.id"
        >
          <van-image width="100%" height="100%" :src="require(`@/assets/cls/p${index+1}.png`)" />
          <div class="p">
            <div class="word">P{{index+1}}</div>
          </div>
          <div class="price">{{item.price}} USDT</div>
          <van-image
            style="position: absolute;bottom:8px;right:11px"
            v-if="i==index"
            width="15px"
            height="15px"
            :src="require('@/assets/cls/pitch.png')"
          />
        </div>
      </div>
      <div style="text-align: center;margin-bottom:35px">
        <van-button color="#00A8FF" @click="buy" :disabled ="userLv>i">{{$t('nft.purchase')}}</van-button>
      </div>
    </div>

    <asidePopup @asideFlagShow="asideFlagShow" style="text-align:left" :asideFlag="asideFlag"></asidePopup>
  </div>
</template>
<script>
import asidePopup from "@/components/common/asidePopup";

export default {
  components: { asidePopup },
  data() {
    return {
      asideFlag: false,
    i: null,
      list: [],
      userLv: null
    };
  },
  methods: {
    asideFlagShow(e) {
      this.asideFlag = e;
    },
    //等级列表
    getList() {
      this.$axios.get(this.$api.get_nft_List, {}).then(res => {
        this.list = res.data.levelConfig;
        this.userLv = res.data.userLv;
      });
    },
    // 立即购买
    buy() {
      let that = this
      if (this.i != null) {
        this.$dialog
          .confirm({
            title: this.$t('nft.grades'),
            message: `${this.$t('nft.aotogrades')}P${this.i + 1}?`,
            confirmButtonColor: "#00A8FF",
            confirmButtonText:this.$t('nft.submit'),
            cancelButtonText:this.$t('nft.cancel')
          })
          .then(() => {
           
            this.$axios.post(this.$api.buy_nft, {id:this.list[this.i].id}).then(res => {
    this.getList();
        
      });
          })
          .catch(() => {
            // on cancel
          });
      }
    }
  },
  created() {
    this.$store.dispatch("getSetting"); //网站配置

    this.getList();
  }
};
</script>
<style lang="scss" scoped>
.main {
  padding: 31px 15px 35px;
  .curren-level {
    margin: 0 auto 37px;
    width: 159px;
    height: 200px;
    position: relative;
     
border-radius: 5px;
    .p {
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 0;
      border-top: 47px solid #e6a23c;
      border-right: 44px solid transparent;
      .level {
        position: absolute;
        left: 5px;
        top: -38px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #fefefe;
      }
    }
    .word {
      height: 29px;
      line-height: 29px;
      background: #000000;
      opacity: 0.5;
      border-radius: 0px 0px 5px 5px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }
  .p0{
    background: linear-gradient(214deg, #C8D1DF, #D9DEEB);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 28px;
    .current {
      border: 1px solid #00a8ff;
    }
    .item {
      position: relative;
      margin-bottom: 10px;
      width: 49%;
      height: 211px;
      &:nth-child(2n-1) {
        margin-right: 2%;
      }
      .p {
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: 50px solid #00a8ff;
        border-right: 46px solid transparent;
        .word {
          position: absolute;
          left: 5px;
          top: -38px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #fefefe;
        }
      }
      .price {
        height: 30px;
        background: #000000;
        opacity: 0.5;
        border-radius: 0px 0px 5px 5px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;

        line-height: 30px;

        text-align: center;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }
  }
}
</style>